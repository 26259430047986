import Box from '@mui/material/Box'
import ErrorIcon from '@mui/icons-material/Error'
import React, { useEffect, useRef, useState } from 'react'
import Skeleton from '@mui/material/Skeleton'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import { Typography } from '@mui/material'

type Props = {
  alt?: string
  error?: string
  height?: number
  imageProps?: React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  >
  onLoad?: (image: HTMLImageElement) => void
  showShadow?: boolean
  source: string
  width?: number
}

type State = {
  error: boolean
  image: React.ReactNode | null
  loaded: boolean
  loading: boolean
}

const isVisible = (el: Element): boolean => {
  const rect = el.getBoundingClientRect()
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) +
        rect.height * 1 &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  )
}

export const ProgressiveImage: React.FC<Props> = ({
  alt = 'No Image Description',
  error,
  height,
  imageProps = {},
  onLoad,
  showShadow = true,
  source,
  width,
}) => {
  const el = useRef(null)
  const [state, setState] = useState<State>({
    error: false,
    image: null,
    loaded: false,
    loading: false,
  })

  useEffect(() => {
    if (el.current && isVisible(el.current)) {
      setState((state) => ({
        ...state,
        loading: true,
      }))
      return
    }
    const interval = setInterval(() => {
      if (el.current && isVisible(el.current)) {
        clearInterval(interval)
        setState((state) => ({
          ...state,
          loading: true,
        }))
      }
    }, 50)

    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    if (state.loading && !state.loaded) {
      const curImg = new window.Image()

      curImg.src = source
      curImg.onerror = function () {
        setState((state) => {
          return {
            ...state,
            error: true,
            loading: false,
          }
        })
      }
      curImg.onload = function () {
        if (onLoad) {
          onLoad(curImg)
        }
        setState((state) => {
          return {
            ...state,
            error: false,
            loaded: true,
            loading: false,
          }
        })
      }
    }
  }, [onLoad, source, state.loading, state.loaded])

  if (!source || error || state.error) {
    return (
      <Box
        sx={{
          backgroundColor: '#ECF1F4',
          boxSizing: 'border-box',
          color: '#A3AAAF',
          height,
          overflow: 'hidden',
          width,
        }}
        style={{
          maxWidth: '100%',
          ...imageProps.style,
        }}
      >
        <WarningAmberIcon
          sx={{
            color: '#A3AAAF',
            height: '50%',
            marginTop: '10%',
            width: '100%',
          }}
        />
        <Typography
          sx={{
            color: '#A3AAAF',
            fontSize: '1.55rem',
            textAlign: 'center',
          }}
        >
          Image not viewable
        </Typography>
      </Box>
    )
  }

  if (!state.loaded || state.loading) {
    return (
      <span ref={el}>
        {showShadow && (
          <Skeleton
            animation="wave"
            height={height}
            width={width}
            sx={{
              maxWidth: '100%',
              width: '100%',
            }}
          />
        )}
      </span>
    )
  }

  return (
    <img
      alt={alt}
      src={source}
      {...imageProps}
      style={{
        display: "block",
        maxWidth: '100%',
        ...imageProps.style,
      }}
    />
  )
}
