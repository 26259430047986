// Main page wrapper/container.
import React from 'react'
import Container from '@mui/material/Container'

import { NavBar } from '../NavBar'

type Props = {
  children: React.ReactNode
}

export const Wrapper: React.FC<Props> = ({ children }) => {
  return (
    <Container maxWidth={false}>
      <NavBar />
      {children}
    </Container>
  )
}
