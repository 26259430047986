// Display nav bar at top of page. Also handles logging in.
import React, { useState } from 'react'
import { 
  AppBar,
  Box,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Toolbar
} from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import { useOktaAuth } from '@okta/okta-react'
import { useNavigate, Link } from 'react-router-dom'

import { DropdownMenu, Image, MenuButton } from '@aletheia/components'

import { VERSION_1_LINK, MACHINE_LOGS_LINK } from '../../environments/environment'
import { filterToVisibleRoutes, routes } from '../../routes'

export const NavBar: React.FC = () => {
  const { authState, oktaAuth } = useOktaAuth()
  const navigate = useNavigate()
  const login = async () => oktaAuth.signInWithRedirect()
  const logout = async () => oktaAuth.signOut()

  const [anchorElNav, setAnchorElNav] = useState<Element | null>(null)

  const handleOpenNavMenu = (event: React.MouseEvent) => {
    setAnchorElNav(event.currentTarget)
  }

  const handleCloseNavMenu = (): void => {
    setAnchorElNav(null)
  }

  const isExternal = (path:string) => path.startsWith("https://")
 
  const handleNavigation = (path:string) => {
    handleCloseNavMenu()
    if (path){
      if (isExternal(path)){
          window.location.href = path
      }
      else {
          navigate(path)
      }
    }
                      
  }

  if (!authState) {
    return null
  }

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar
          disableGutters
          sx={{
            width: '100%',
            mx: 'auto',
          }}
        >
          <Link to="/">
            <Image
              source="assets/branding/BRTLogo-04.png"
              height={38}
              width={151}
              alt="BRT Logo"
            />
          </Link>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            {authState.isAuthenticated && (
              <>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="inherit"
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{
                    display: { xs: 'block', md: 'none' },
                  }}
                >
                  {routes.filter(filterToVisibleRoutes).map((route) => {
                    if (route.childRoutes){
                      return route.childRoutes.map(({label, path}) => (
                        <MenuItem
                          key={path}
                          onClick={() => handleNavigation(path)}
                        >
                          {label}
                        </MenuItem>)
                      )
                    }
                    else {
                      return(
                        <MenuItem
                          key={route.path}
                          onClick={() => handleNavigation(route.path)}
                        >
                          {route.label}
                        </MenuItem>)
                      }
                  })}
                  <MenuItem onClick={() => {
                    window.location.href = MACHINE_LOGS_LINK
                  }}>
                      Machine Logs
                  </MenuItem>
                </Menu>
              </>
            )}
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            <Stack direction="row" spacing={2}>
              {authState.isAuthenticated && (
                <>
                  {routes.filter(filterToVisibleRoutes).map((route) => {
                    if (route.childRoutes) {
                      return (
                        <DropdownMenu 
                          key={route.label}
                          label={route.label}
                          childRoutes={route.childRoutes}
                        />)
                    } else {
                      return (
                        <MenuButton
                          key={route.label}
                          onClick={() => handleNavigation(route.path)} 
                          label={route.label}
                          path={route.path}
                        />)
                    }
                  }
                  )}
                  <MenuButton onClick={
                    () => {window.location.href = MACHINE_LOGS_LINK}
                    }
                    label="Machine Logs"
                  />
                </>
                )}
            </Stack>
          </Box>

          <Box sx={{ flexGrow: 0, display: { xs: 'flex', md: 'flex' } }}>
            {!authState.isAuthenticated && (
              <MenuButton
                onClick={login}
                label="Login"
              />
            )}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            {authState.isAuthenticated && (
              <Stack direction="row" spacing={2}>
                <MenuButton
                  onClick={() => {
                    window.location.href = VERSION_1_LINK
                  }}
                  label='Legacy Aletheia'
                />
                
                <MenuButton
                  onClick={logout}
                  label={"Logout"}
                />
              </Stack>
            )}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  )
}
