import {useOktaAuth} from '@okta/okta-react'
import {Outlet} from 'react-router-dom'
import Skeleton from '@mui/material/Skeleton'
import {toRelativeUrl} from "@okta/okta-auth-js";

type Props = {
  children?: React.ReactNode
}

export const SecureRoute: React.FC<Props> = () => {
  const {oktaAuth, authState} = useOktaAuth()

  // automatically trigger the login flow if the user is not logged in
  oktaAuth.authStateManager.subscribe(authState => {
    if (!authState?.isAuthenticated) {
      const originalUri = toRelativeUrl(window.location.href, window.location.origin);
      oktaAuth.signInWithRedirect({originalUri: originalUri});
    }
  });

  if (!authState || !authState?.isAuthenticated) {
    return <Skeleton variant="rounded"/>
  } else {
    return <Outlet/>
  }
}
