// @ts-nocheck
import React, { useMemo } from 'react'
import { Column, usePagination, useSortBy, useTable } from 'react-table'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'

import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'

import { Pagination } from '../Pagination'

export type PaginatedTableProps = {
  columns: Array<Column>
  data: object[]
  dataTestID?: string
  firstPage?: (() => void) | false
  lastPage?: (() => void) | false
  nextPage?: (() => void) | false
  noResultText?: string
  onChangePerPage?: (perPage: number) => void
  paginated?: boolean
  paginationText?: string
  perPage?: number
  prevPage?: (() => void) | false
  sortable?: boolean
  totalRows?: number
  showPagination?: boolean
}

export const PaginatedTable: React.FC<PaginatedTableProps> = ({
  columns,
  data,
  dataTestID,
  firstPage = false,
  lastPage = false,
  nextPage = false,
  noResultText = 'No results',
  onChangePerPage,
  paginated = true,
  paginationText = null,
  perPage = 50,
  prevPage = false,
  sortable = true,
  totalRows = null,
  showPagination = true,
}) => {
  const memoColumns = useMemo(() => columns, [columns])
  const memoData = useMemo(() => data, [data])

  const plugins = []
  if (sortable) {
    plugins.push(useSortBy)
  }
  if (paginated) {
    plugins.push(usePagination)
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    pageOptions,
    state: { pageIndex },
  } = useTable(
    {
      columns: memoColumns,
      data: memoData,
      initialState: {
        pageSize: perPage,
      },
    },
    ...plugins
  )

  if (memoData.length === 0) {
    return (
      <div
        className="centered"
        style={{
          marginTop: '200px',
          minHeight: '400px',
        }}
      >
        <h4>{noResultText}</h4>
      </div>
    )
  }

  return (
    <TableContainer component={Paper} data-testid={dataTestID}>
      <Table stickyHeader {...getTableProps()}>
        <TableHead>
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <TableCell
                  {...column.getHeaderProps(
                    column.getSortByToggleProps && column.getSortByToggleProps()
                  )}
                  sx={{
                    borderColor: 'rgba(224, 224, 224, 1)',
                    borderStyle: 'solid',
                    borderWidth: '1px',
                    fontWeight: 'bold',
                    p: 1,
                    textAlign: 'center',
                  }}
                >
                  {column.render('Header')}{' '}
                  {column.isSorted &&
                    (column.isSortedDesc ? (
                      <ArrowDropDownIcon />
                    ) : (
                      <ArrowDropUpIcon />
                    ))}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row)
            return (
              <TableRow {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <TableCell
                      {...cell.getCellProps()}
                      sx={{
                        borderColor: 'rgba(224, 224, 224, 1)',
                        borderStyle: 'solid',
                        borderWidth: '1px',
                      }}
                    >
                      {cell.render('Cell')}
                    </TableCell>
                  )
                })}
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
      {showPagination && (
        <Pagination
          firstPage={firstPage}
          lastPage={lastPage}
          onChangePerPage={onChangePerPage}
          paginationText={
            paginationText ||
            `Page ${pageIndex + 1} of ${pageOptions.length}${
              totalRows && ` Total Rows: ${totalRows}`
            }`
          }
          perPage={perPage}
          prevPage={prevPage}
          nextPage={nextPage}
        />
      )}
    </TableContainer>
  )
}
