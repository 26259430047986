// Main App component
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { Okta, SecureRoute, createOktaAuth } from '@aletheia/okta'
import { Route, Routes } from 'react-router-dom'
import { StrictMode } from 'react'
import { ThemeProvider } from '@mui/material/styles'
import { useNavigate } from 'react-router-dom'

import { Wrapper } from './components/Wrapper'
import { routes } from './routes'
import { theme } from './theme'
import * as env from './environments/environment'
import { SnackbarProvider } from 'notistack'

const { OKTA_WEB_CLIENT_ID } = env

const oktaAuth = createOktaAuth(OKTA_WEB_CLIENT_ID)

const App = () => {
  const navigate = useNavigate()

  return (
    <StrictMode>
      <ThemeProvider theme={theme}>
        <SnackbarProvider>

          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Okta config={oktaAuth} navigate={navigate}>
              <Wrapper>
                <Routes>
                  {routes.map((route) => {
                    if (route.secure === false) {
                      return (
                        <Route
                          element={route.component}
                          key={route.path}
                          path={route.path}
                        />
                      )
                    }
                    return (
                      <Route
                        key={route.path}
                        element={<SecureRoute>{route.component}</SecureRoute>}
                        path={route.path}
                      >
                        <Route element={route.component} index />
                      </Route>
                    )
                  })}
                </Routes>
              </Wrapper>
            </Okta>
          </LocalizationProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </StrictMode>
  )
}
export default App
