import {Button, FormControl, Stack, TextField} from "@mui/material"
import {SelectSingle} from "../Select"
import {DateRangePicker} from "../DateRangePicker"
import {useNavigate} from "react-router-dom"

type Filter = {
  key: string
  label: string
  options: unknown
}

interface IFilterBar {
  filters: Filter[]
  filterState: Record<string, string | undefined>
  setFilterState: (filterState: Record<string, string | undefined>) => void
  // need to move this method with some others to a separate project
  getFilterStateFromUrl: (search: string, keys: string[], useArray?: boolean) => Record<string, any>
  route: string
}

export const FilterBar: React.FC<IFilterBar> = (
  {
    filters,
    filterState,
    setFilterState,
    getFilterStateFromUrl,
    route
  }) => {
  const navigate = useNavigate()

  const resetFilters = () => {
    setFilterState({})
    navigate(`/${route}`)
  }

  return (
    <Stack direction="row" mb={2} mt={2} flexWrap="wrap">
      {filters.map(({key, label, options}) => {
        if (Array.isArray(options)) {
          const widthInCh = Math.max(...options.map((option) => option.id.length))

          return (
            <SelectSingle
              id={`filter-${label}`}
              key={key}
              value={filterState[key] as string}
              sx={{mr: 2, mt: 2}}
              label={label}
              onChange={(newSelectedItemId) =>
                setFilterState({...getFilterStateFromUrl, [key]: newSelectedItemId})
              }
              options={options}
              width={`${widthInCh + 10}ch`}
            />
          )
        } else if (options === "text") {
          return (
            <FormControl sx={{mr: 2, mt: 2}} key={key}>
              <TextField
                label={label}
                value={filterState[key] as string || ""}
                variant="standard"
                onChange={(e) =>
                  setFilterState({...getFilterStateFromUrl, [key]: e.target?.value || ""})
                }
              />
            </FormControl>
          )
        } else {
          // Backward compatible with previous behavior, where no value meant date picker
          return (
            <DateRangePicker
              key={key}
              label={label}
              route={route}
              value={filterState[key]}
              filterState={filterState}
              setFilterState={setFilterState}
              getFilterStateFromUrl={getFilterStateFromUrl}
            />
          )
        }
      })}
      <Button sx={{mt: 2}} variant="outlined" onClick={resetFilters}>Reset filters</Button>
    </Stack>
  )
}
