import { Menu, MenuItem, MenuProps, styled } from "@mui/material"
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { MenuButton } from "../MenuButton";



const StyledMenu = styled((props: MenuProps) => (
    <Menu
      elevation={0}
      hideBackdrop
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      {...props}
    />
  ))(({ theme }) => ({
    '& .MuiPaper-root': {
      marginTop: theme.spacing(1),
      minWidth: 180,
      color: theme.palette.primary.main
    },
  }));

type ChildRoute = {
    label: string
    path: string
}

interface IDropdownMenu {
    label: string,
    childRoutes: ChildRoute[]
}

export const DropdownMenu:React.FC<IDropdownMenu> = ({label, childRoutes}) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const navigate = useNavigate()
    const open = Boolean(anchorEl);

    const isExternal = (path: string) => path.startsWith('https://') 

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = (_: React.MouseEvent, path?: string) => {
      setAnchorEl(null);
      if (path){
        if (isExternal(path)){
            window.location.href = path
        }
        else {
            navigate(path)
        }
      }
    };

    return (
        <>
         <MenuButton
            aria-controls={open ? 'dropdown-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            label={label}
            endIcon={<span style={{
              borderTop: open ? "0" : "0.3em solid",
              borderRight: "0.3em solid transparent",
              borderBottom: open ? "0.3em solid" : "0",
              borderLeft: "0.3em solid transparent"
            }}></span>}
        />
        <StyledMenu
            id="dropdown-menu"
            MenuListProps={{
                'aria-labelledby': 'dropdown-menu-button',
                onMouseLeave: handleClose
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            disableAutoFocusItem
        >
            {childRoutes.map(({label, path}) => (
                <MenuItem 
                  key={label}
                  onClick={(e: React.MouseEvent) => handleClose(e,path)}
                >
                    {label}
                </MenuItem>
            ))}
        </StyledMenu>
        </>
    )
}