import {Paper, Table, TableBody, TableCell, TableRow} from "@mui/material"

type NestedKeyOf<T extends object> =
  {
    [Key in keyof T & (string | number)]: T[Key] extends object
    ? `${Key}` | `${Key}.${NestedKeyOf<T[Key]> extends infer U extends string ? U : never}`
    : `${Key}`
  }[keyof T & (string | number)];

export type Row<T extends object> = {
  Header: string
  id: NestedKeyOf<T>
  accessor?: (value: any) => string | JSX.Element | null
}

interface IDetailsTable<T extends object> {
  data: T,
  rows: Row<T>[]
}

export const DetailsTable = <T extends Record<string, unknown>>(
  {
    data,
    rows
  }: IDetailsTable<T>
) => {

  const getNestedValue: (data: any, path: string) => string = (data, path) => {
    if (data === null || data === undefined) {
      return '';
    }
    const pathArr = path.split(".")
    const [key, ...rest] = pathArr
    if (pathArr.length === 1) {
      return data[key] || '';
    }
    return getNestedValue(data[key], rest.join("."));
  }

  return (
    <Paper>
      <Table>
        <TableBody>
          {
            rows.map(({Header, id, accessor}) => (
              <TableRow key={id}>
                <TableCell variant="head" sx={{p: 2}}>{Header}</TableCell>
                <TableCell sx={{p: 2}}>{
                  (accessor ?
                    accessor(getNestedValue(data, id as string)) :
                    getNestedValue(data, id as string))
                }</TableCell>
              </TableRow>
            ))
          }
        </TableBody>
      </Table>
    </Paper>
  )
}
