import { IconButton, Stack } from "@mui/material"
import { IconTextField } from "../IconTextField"
import ClearIcon from '@mui/icons-material/Clear';
import { useCallback, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { useNavigate } from "react-router-dom";

interface IDateRangePicker {
    label: string
    route: string
    filterState: Record<string, string | undefined>
    setFilterState: (filterState: Record<string, string | undefined>) => void
    // need to move this method with some others to a separate project
    getFilterStateFromUrl: (search: string, keys: string[], useArray?: boolean) => Record<string, any>
    value?: string
}

export const DateRangePicker:React.FC<IDateRangePicker> = ({
    label,
    value,
    route,
    filterState,
    setFilterState,
    getFilterStateFromUrl
}) => {
    const [dateRange, setDateRange] = useState<(Date | null)[]>([null, null]);
    const [startDate, endDate] = dateRange
    const navigate = useNavigate()

    const clearDateRange = (e: React.MouseEvent) => {
        e.stopPropagation()
        setDateRange([null, null])
        delete filterState['created_at']
        setFilterState({...filterState})
        navigate(`/${route}?${new URLSearchParams(filterState as Record<string, string>)}`)
    }

    const getValuesFromDateRangeString = useCallback(
        (dateRangeStr: string) => dateRangeStr.split(',').map((dateStr) => dateStr ? new Date(Number(dateStr)) : null)
    ,[value])
    
    const createDateRangeString = useCallback((startDate:Date, endDate:Date) => 
      `${startDate.getTime()},${endDate.getTime()}`
    , [])

    const onDateRangeChange = (dates: [Date | null, Date | null]) => {
      const [startDate, endDate] = dates
      if (startDate && endDate){
        setFilterState({...getFilterStateFromUrl, created_at: createDateRangeString(startDate, endDate)})
      }
      setDateRange(dates)
    };

    useEffect(() => {
      if (value){
          setDateRange(getValuesFromDateRangeString(value))
      }
    },[value])

    return (
      <Stack sx={{mr: 2, mt: 2}}>
        <DatePicker
          todayButton="today"
          selected={null}
          onChange={onDateRangeChange}
          startDate={startDate}
          endDate={endDate}
          customInput={
            <IconTextField 
              label={label} 
              iconEnd={(endDate || startDate) ? <IconButton onClick={clearDateRange}><ClearIcon /></IconButton> : null}
              variant="standard"
            />
          }
          monthsShown={2}
          selectsRange
        />
      </Stack>
    )
}