// Controls all routes. Setting a routes `visible` to false hides it from main nav at top.
import {RedirectV3} from './pages/NotFoundPage'

type ChildRoute = {
  label: string
  path: string
}

export type Route = {
  component?: React.ReactNode
  label: string
  path: string
  secure?: boolean
  visible?: boolean
  childRoutes?: ChildRoute[]
}

export const filterToVisibleRoutes = (route: Route) => {
  return route.visible !== false
}

export const routes: Array<Route> = [
  // {
  //   component: <HomePage/>,
  //   label: 'Home',
  //   path: '/',
  //   secure: false,
  //   visible: false,
  // },
  // {
  //   component: <LoginCallback/>,
  //   label: 'Okta Login Callback',
  //   path: '/login/callback',
  //   secure: false,
  //   visible: false,
  // },
  // {
  //   component: <ImagePage/>,
  //   label: 'Images',
  //   path: '/images',
  //   visible: true,
  //   childRoutes: [
  //     {
  //       label: "Images",
  //       path: "/images"
  //     },
  //     {
  //       label: "Analytical Image Search App",
  //       path: "https://apps.prod.mesa.brtws.com/graphs"
  //     }
  //   ]
  // },
  // {
  //   component: <ImagePage/>,
  //   label: 'Images',
  //   path: '/images/:imageID',
  //   visible: false,
  // },
  // {
  //   component: <JobsPage/>,
  //   label: 'Jobs',
  //   path: '/jobs',
  //   visible: true,
  // },
  // {
  //   component: <JobsPage/>,
  //   label: 'Jobs',
  //   path: '/jobs/:jobId',
  //   visible: false,
  // },
  // {
  //   component: <DatasetPage/>,
  //   label: 'Datasets',
  //   path: '/datasets',
  // },
  // {
  //   component: <DatasetPage/>,
  //   label: 'Datasets',
  //   path: '/datasets/:datasetID',
  //   visible: false,
  // },
  // {
  //   component: <TasksListPage/>,
  //   label: 'Tasks',
  //   path: '/tasks',
  //   visible: true,
  // },
  // {
  //   component: <TasksDetailsPage/>,
  //   label: 'Tasks',
  //   path: '/tasks/:taskID',
  //   visible: false
  // },
  // {
  //   component: <AnalysesListPage/>,
  //   label: 'Analyses',
  //   path: '/analyses',
  //   visible: true,
  // },
  // {
  //   component: <AnalysisDetailsPage/>,
  //   label: 'Analyses',
  //   path: '/analysis/:analysisId',
  //   visible: false,
  // },
  // {
  //   component: <AuditPage/>,
  //   label: 'Audit',
  //   path: '/audits',
  //   visible: false,
  // },
  // {
  //   component: <ModelsListPage/>,
  //   label: 'Models',
  //   path: '/machine_learning_models',
  //   visible: true,
  // },
  // {
  //   component: <ModelStatusListPage/>,
  //   label: 'Models',
  //   path: '/machine_learning_models/status',
  //   visible: false
  // },
  // {
  //   component: <ModelDetails/>,
  //   label: 'Models',
  //   path: '/machine_learning_models/:modelId',
  //   visible: false
  // },
  // {
  //   component: <EvaluationsListPage/>,
  //   label: 'Evaluations',
  //   path: '/model_evaluations',
  //   visible: true,
  // },
  // {
  //   component: <EvaluationsDetailsPage/>,
  //   label: 'Evaluations',
  //   path: '/model_evaluations/:evaluationId',
  //   visible: false
  // },
  // {
  //   component: <ImageEvaluationsPage/>,
  //   label: 'Image Evaluations Page',
  //   path: '/model_evaluations/:evaluationId/image_evaluations/:imageId',
  //   visible: false
  // },
  // {
  //   component: <AggregatedDataPage/>,
  //   label: 'Aggregated Data',
  //   path: '/aggregated_data',
  //   visible: false,
  // },
  // {
  //   component: <CollectionOverviewPage/>,
  //   label: 'Collection Overview',
  //   path: '/collection_overview',
  //   visible: false,
  // },
  // {
  //   component: <ProfilePage/>,
  //   label: 'Profile',
  //   path: '/profile',
  //   visible: false,
  // },
  // {
  //   component: <UserListPage/>,
  //   label: 'Users',
  //   path: '/users',
  //   visible: true,
  // },
  // {
  //   component: <UserDetailsPage/>,
  //   label: 'User Details',
  //   path: '/users/:userId',
  //   visible: false,
  // },
  // {
  //   component: <MachineListPage/>,
  //   label: 'Machines',
  //   path: '/machines',
  //   visible: true,
  // },
  // {
  //   component: <MachineDetailsPage/>,
  //   label: 'Machine Details',
  //   path: '/machines/:machineId',
  //   visible: false,
  // },
  // {
  //   component: <NotFoundPage/>,
  //   label: 'Not Found',
  //   path: '*',
  //   secure: false,
  //   visible: false,
  // },
  {
    component: <RedirectV3/>,
    label: 'Not Found',
    path: '*',
    secure: false,
    visible: false,
  },
]
