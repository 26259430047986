// This file can be replaced during build by using the `fileReplacements` array.
// When building for production, this file is replaced with `environment.prod.ts`.
// These values should be local/dev values

export const env = "dev"
export const API_HOST = 'https://tartarus-dev-api.brtws.com'
export const DEBUG_LOGGING = true
export const OKTA_WEB_CLIENT_ID = '0oalfcnr3ulTEIhqG357'
export const OKTA_DEVKIT_CLIENT_ID = '0oa557jtz3gC6nG18357'
export const GA_TRACKING_ID = 'UA-57393998-3'
export const VERSION_1_LINK = 'https://aletheia.tartarus.dev.mesa.brtws.com'
export const KUBEFLOW_URL = 'https://kubeflow.prod.mesa.brtws.com'
export const MACHINE_LOGS_LINK = `https://apps.${env}.mesa.brtws.com/logs`
