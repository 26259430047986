// Placeholder for possible future page.
import React from 'react'
import Typography from '@mui/material/Typography'

export const NotFoundPage = () => {
  return (
    <div>
      <Typography variant="h2" gutterBottom>
        Page Not Found
      </Typography>
    </div>
  )
}
