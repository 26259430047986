import { Button } from "@mui/material"

interface IMenuButton {
    onClick: (event: React.MouseEvent<HTMLElement>) => void
    label: string
    path?: string
    endIcon?: JSX.Element
}

export const MenuButton:React.FC<IMenuButton> = ({onClick, label, path, endIcon}) => {
    return (
        <Button
            key={path}
            onClick={onClick}
            sx={{ my: 2, color: 'white' }}
            endIcon={endIcon}
        >
            {label}
        </Button>
    )
}