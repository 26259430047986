import {NavigateFunction} from 'react-router-dom'
import {OktaAuth, toRelativeUrl} from '@okta/okta-auth-js'
import {Security} from '@okta/okta-react'
import {config} from './config'

type Props = {
  children: React.ReactNode
  config: OktaAuth
  navigate: NavigateFunction
}

export const createOktaAuth = (clientId: string): OktaAuth => {
  return new OktaAuth({
    clientId: clientId,
    issuer: config.issuer,
    redirectUri: `${window.location.origin}/login/callback`,
    scopes: config.scopes,
    pkce: config.pkce,
  })
}

export const Okta: React.FC<Props> = ({children, config, navigate}) => {
  const restoreOriginalUri = (
    _oktaAuth: OktaAuth,
    originalUri: string
  ): void => {
    navigate(toRelativeUrl(originalUri || '/', window.location.origin))
  }

  return (
    <Security oktaAuth={config} restoreOriginalUri={restoreOriginalUri}>
      {children}
    </Security>
  )
}
