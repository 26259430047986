import React from 'react'

type Props = {
  alt?: string
  height?: number
  source: string
  style?: Record<string, unknown>
  width?: number
}
export const Image: React.FC<Props> = ({
  alt,
  height = 100,
  source,
  style = {},
  width = 100,
  ...props
}) => {
  return (
    <img
      alt={alt || 'No image description'}
      height={height}
      src={source}
      style={style}
      width={width}
      {...props}
    />
  )
}
