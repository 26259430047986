import {
  Autocomplete,
  FormControl,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
  TextField,
} from '@mui/material'
import React from 'react'

export type SelectOption = {
  id: string
  label: string
}
export type SelectProps = {
  fullWidth?: boolean
  label: string
  value: string | undefined
  options: SelectOption[]
  onChange: (selectedValueId: string | undefined) => void
  className?: string
  sx?: Record<string, any>
  width?: string | number
  'data-testid'?: string
  id?: string
}
export const SelectSingle: React.FC<SelectProps> = ({
  fullWidth = false,
  label,
  value,
  options,
  onChange,
  className,
  width,
  sx = {},
  ...divProps
}) => {
  const foundOption = options.find((option) => option.id === value)
  return (
    <FormControl className={className} fullWidth={fullWidth} {...divProps}>
      <Autocomplete
        fullWidth={fullWidth}
        value={foundOption || null}
        sx={{
          ...sx,
          width,
        }}
        onChange={(_event, value) => {
          onChange(value?.id || value?.label || '')
        }}
        renderInput={(params) => (
          <TextField {...params} label={label} variant="standard" />
        )}
        options={options}
      />
    </FormControl>
  )
}
