// Creates MUI theme
import { createTheme } from '@mui/material/styles'

export const theme = createTheme({
  components: {
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          minWidth: 150,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          minHeight: 40,
          textTransform: 'capitalize',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          minWidth: 150,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: 2,
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          borderCollapse: 'collapse',
          borderSpacing: 0,
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          minWidth: 150,
        },
      },
    },
  },
  palette: {
    primary: {
      main: '#002A5D',
    },
    secondary: {
      main: '#ffffff',
    },
  },
})
