import React, {useEffect} from 'react'
import {VERSION_1_LINK} from "../../environments/environment";

export const RedirectV3 = () => {

  useEffect(() => {
    window.location.href = `${VERSION_1_LINK}${window.location.pathname}${window.location.search}${window.location.hash}`;
  }, []);

  return (
    <div>
      Redirecting you...
    </div>
  )
}
