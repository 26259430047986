import { Button, Paper, Table, TableBody, TableCell, TableFooter, TableHead, TablePagination, TableRow } from "@mui/material"
import { Link } from "react-router-dom"

interface Column<T> {
    Header: string
    id: keyof T
    accessor?: (value: string) => string | JSX.Element
    minWidth?: number,
    sorted?: 'asc' | 'desc',
}

interface IResultList<T> {
    rows: T[]
    columns: Column<T>[]
    pageSize: number
    setPageSize: (tasksPageSize: number) => void
    currentPage: number
    setCurrentPage: (newCurrentPage: number) => void
    nextPage: number | null
    route: string,
    callBacks?: Partial<Record<keyof T, (data: any) => void>>
}

export const ResultList = <T extends Record<string, unknown>>({
  rows,
  columns,
  pageSize,
  setPageSize,
  currentPage,
  setCurrentPage,
  nextPage,
  route,
  callBacks
}:IResultList<T>) => {
    // Use -1 to indicate there is more data available, but we don't know exactly how much
    const getCount = () => nextPage ? -1 : pageSize * currentPage + rows.length

    const hasCallback = (key: keyof T) => !!callBacks?.[key]

    const getCellValue = (key: keyof T, data: any, accessor?: (data: any) => string | JSX.Element) => {
      if (accessor){
        return accessor(data)
      } else if (hasCallback(key)){
        return <Button onClick={() => callBacks?.[key]?.(data)}>{data}</Button>
      } else  if (key === 'id'){
        return <Link to={`/${route}/${data}`}>{data as unknown as string}</Link>
      } else if (Array.isArray(data)) {
        return data.join(", ");
      } else {
        return data
      }
    }


    return (
        <Paper sx={{mt: 2}}>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map(({Header, minWidth, sorted}) => (
                  <TableCell key={Header} style={{ minWidth, fontWeight: '900' }}>
                    {Header}
                    {sorted === "asc" && String.fromCharCode(9650)}
                    {sorted === "desc" && String.fromCharCode(9660)}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
                {rows.length > 0 ?
                  rows.map((row, index) => (
                    <TableRow key={index}>
                      {columns.map(({id, accessor}, idx) => (
                        <TableCell key={`${index}${String(id)}${idx}`}>
                          {getCellValue(id, row[id], accessor)}
                        </TableCell>
                      ))}
                    </TableRow>
                )) :
                  <TableRow>
                    <TableCell colSpan={columns.length} align='center' sx={{p:2}}>
                      No rows found
                    </TableCell>
                  </TableRow>}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  component="td"
                  rowsPerPageOptions={[5,10,25,50]}
                  colSpan={columns.length}
                  count={getCount()}
                  page={currentPage}
                  rowsPerPage={pageSize}
                  onRowsPerPageChange={((e) => setPageSize(parseInt(e.target.value, 10)))}
                  onPageChange={(_,newPage: number) => setCurrentPage(newPage)}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </Paper>
    )
}
