import {forwardRef} from "react";
import {BaseTextFieldProps, InputAdornment, TextField} from "@mui/material";

interface IIconTextField extends BaseTextFieldProps {
  iconStart?: JSX.Element | null,
  iconEnd?: JSX.Element | null,
  onChange?: (selectedValueId: string | undefined) => void,
}

export const IconTextField: React.FC<IIconTextField> = forwardRef((
  {iconStart, iconEnd, onChange, ...props},
  ref
) => {
  return (
    <TextField
      ref={ref}
      {...props}
      onChange={(event) => {
        if (onChange) {
          onChange(event.target?.value || '')
        }
      }}
      InputProps={{
        startAdornment: iconStart ? (
          <InputAdornment position="start">{iconStart}</InputAdornment>
        ) : null,
        endAdornment: iconEnd ? (
          <InputAdornment position="end">{iconEnd}</InputAdornment>
        ) : null
      }}
    />
  );
});
