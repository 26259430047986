import React from 'react'
import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl'
import Grid from '@mui/material/Grid'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'

import Typography from '@mui/material/Typography'

type Props = {
  firstPage?: (() => void) | false
  lastPage?: (() => void) | false
  onChangePerPage?: (perPage: number) => void
  nextPage?: (() => void) | false
  paginationText: string
  perPage: number
  prevPage?: (() => void) | false
}

const perPageOptions = [
  {
    id: '5',
    label: '5',
  },
  {
    id: '10',
    label: '10',
  },
  {
    id: '25',
    label: '25',
  },
  {
    id: '50',
    label: '50',
  },
]

export const Pagination: React.FC<Props> = ({
  firstPage,
  lastPage,
  nextPage,
  onChangePerPage,
  paginationText,
  perPage,
  prevPage,
}) => {
  return (
    <Grid container>
      <Grid className="centered padding5" item xs={2}>
        {firstPage && (
          <Button
            className="fullWidth"
            variant="outlined"
            style={{
              width: '100%',
            }}
            onClick={firstPage}
          >
            First
          </Button>
        )}
      </Grid>
      <Grid item xs={2} className="centered padding5">
        <Button
          className="fullWidth"
          variant="outlined"
          onClick={() => {
            prevPage && prevPage()
          }}
          disabled={!prevPage}
          style={{
            width: '100%',
          }}
        >
          Previous
        </Button>
      </Grid>
      <Grid item xs={4} className="centered padding5">
        <Grid container>
          <Grid item xs={6}>
            <Typography
              sx={{
                marginTop: '4px',
              }}
              variant="h6"
            >
              {paginationText}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth size="small">
              <InputLabel id="pagination-per-page-label">Per Page</InputLabel>
              <Select
                labelId="pagination-per-page-label"
                id="pagination-per-page"
                label="Per Page"
                onChange={(e) => {
                  if (onChangePerPage && e?.target?.value) {
                    onChangePerPage(e?.target?.value as number)
                  }
                }}
                value={perPage}
              >
                {perPageOptions.map((option) => {
                  return (
                    <MenuItem key={option.id} value={option.id}>
                      {option.label}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={2} className="centered padding5">
        <Button
          data-testid='pagination-next'
          className="fullWidth"
          variant="outlined"
          onClick={() => {
            nextPage && nextPage()
          }}
          disabled={!nextPage}
        >
          Next
        </Button>
      </Grid>
      <Grid item xs={2} className="centered padding5">
        {lastPage && (
          <Button className="fullWidth" onClick={lastPage} variant="outlined">
            Last
          </Button>
        )}
      </Grid>
    </Grid>
  )
}
