import { Accordion, AccordionDetails, AccordionSummary, Stack, Typography } from "@mui/material"

interface ISimpleAccordion {
    summary: string
    content: JSX.Element
}

export const SimpleAccordion:React.FC<ISimpleAccordion> = ({ summary, content }) => {
    return (
        <Stack sx={{mt:2}}>
            <Accordion>
                <AccordionSummary>
                    <Typography>
                        {summary}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {content}
                </AccordionDetails>
            </Accordion>
        </Stack>
    )
}