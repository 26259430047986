import lottie from 'lottie-web'
import { useEffect, useRef } from 'react'
import data from './tractor.json'

type Props = {
  height?: number
  style?: Record<string, unknown>
  width?: number
}

export const LoadingTractor: React.FC<Props> = ({
  height = 450,
  style = {},
  width = 450,
}) => {
  const ref = useRef<HTMLDivElement>(null)
  useEffect(() => {
    // We do this to prevent showing the animation twice.
    if (ref.current?.childNodes.length === 0) {
      lottie.loadAnimation({
        container: ref.current,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: data,
      })
    }
  }, [])

  return (
    <div
      className="loading tractor"
      ref={ref}
      style={{
        height,
        width,
        ...style,
      }}
    ></div>
  )
}
