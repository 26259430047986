import * as React from 'react';
import {Theme, useTheme} from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, {SelectChangeEvent, SelectProps} from '@mui/material/Select';
import Chip from '@mui/material/Chip';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name: string, personName: readonly string[], theme: Theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

interface MultiSelectProps {
  label: string,
  options: string[],
  value: string[],
  onValueChange?: (value: string[]) => void,
}

export const MultiSelect: React.FC<MultiSelectProps & SelectProps<string[]>> = (
  {
    label,
    options,
    value,
    onValueChange,
    ...props
  }) => {
  const theme = useTheme();

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: {value},
    } = event;
    if (onValueChange) {
      onValueChange(typeof value === 'string' ? value.split(',') : value);
    }
  };

  return (
    <div>
      <FormControl sx={{m: 0, width: '100%'}}>
        <InputLabel id={`multiple-label-${label}`}>{label}</InputLabel>
        <Select
          {...props}
          labelId={`multiple-label-${label}`}
          id={`multiple-${label}`}
          multiple
          value={value}
          onChange={handleChange}
          input={<OutlinedInput id={`multiple-${label}`} label={label}/>}
          renderValue={(selected) => (
            <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
              {selected.map((value) => (
                <Chip key={value} label={value}/>
              ))}
            </Box>
          )}
          MenuProps={MenuProps}>
          {options.map((name) => (
            <MenuItem
              key={name}
              value={name}
              style={getStyles(name, value, theme)}>
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
